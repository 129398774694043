









































































































































































import ViewModel from '@/views/company/Logo.ts';
export default ViewModel;
